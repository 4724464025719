input[type=checkbox] {
    opacity: initial;
}

.table thead>tr>td,
.table tbody>tr>td,
.table tfoot>tr>td,
.table thead>tr>th,
.table tbody>tr>th,
.table tfoot>tr>th {
    padding: 5px;
    border: 1px solid #e8e7eb;
}

.show {
    opacity: 1;
}
.modal.fade .modal-dialog{
    transform: none;
}

.modal-backdrop.fade{
    z-index: 999 !important;
    opacity: 0.5;
}


.input-invalid
{
    border:1px solid #f00;
}

select {
    -webkit-appearance: menulist;
}

.dropdown-btn
{
    border: 0!important;
}

/* Remove spinners from number and date fields */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button,
input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    /* Chrome */
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

.nav-item>.nav-link.active {
    background-color: #041e42;
    color: white;
}

.nav-item>.nav-link.active:hover {
    background-color: #a4adbb;
    color: white;
}

.nav-item>.nav-link:hover {
    background-color: #a4adbb;
    color: white;
}

input::-webkit-calendar-picker-indicator {
    margin: 0px;
    padding: 0px;
}

.toast-download .slider {
    position: absolute;
    width: 20rem;
    height: 5px;
    overflow-x: hidden;
}

.toast-download .line {
    position: absolute;
    opacity: 0.4;
    background: #4a8df8;
    width: 150%;
    height: 5px;
}

.toast-download .subline {
    position: absolute;
    background: #4a8df8;
    height: 5px;
}

.toast-download .subline.inc {
    animation: toast-progress-increase 2s infinite;
}

.toast-download .subline.dec {
    animation: toast-progress-decrease 2s 0.5s infinite;
}

@keyframes toast-progress-increase {
    from {
        left: -5%;
        width: 5%;
    }

    to {
        left: 130%;
        width: 100%;
    }
}

@keyframes toast-progress-decrease {
    from {
        left: -80%;
        width: 80%;
    }

    to {
        left: 110%;
        width: 10%;
    }
}

.toast-download {
    background-image: none !important;
}

.toast-download .toast-title:before {
    position: relative;
    font-family: 'Font Awesome 5 Free';
    font-size: 24px;
    line-height: 18px;
    float: left;
    margin-left: -1.5em;
    color: #FFF;
    padding-right: 0.5em;
}

.toast-download .toast-title:before {
    content: "\f019";
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

c-navigation[stuck=true] {
    z-index: 999;
}

.cdk-global-scrollblock {
    position: static !important;
    width: initial !important;
    overflow-y: inherit !important;
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
    display: block !important;
}

input[type=checkbox] {
    opacity: 0;
}